import port from '@config/port';
import { MD5 } from 'crypto-js';
import systemInfo from '@/stores/systemInfo';
import { getPlatform, AES, toLogin } from './methods';
import type { service, method, params, reqData, resData, storageReqParams } from '@config/types';

export const md5 = (data: string) => MD5(data).toString();
const needAES = ['pwd', 'rePwd', 'safePwd', 'pwdRepeat'];

/** 等待请求响应队列 */
const reqQueue: any = new Map();
export const mergeReq = (params: params): Promise<any> => {
	return new Promise(async (resolve) => {
		let port = params.port;

		if (reqQueue.get(port)) {
			reqQueue.get(port).push(resolve);
		} else {
			reqQueue.set(port, [resolve]);
			let info: any;
			try {
				info = await request.get({ ...params, showLoad: false, resErr: true });
			} catch (error) {
				info = error;
			}
			reqQueue.get(port).forEach((fun: Function) => fun(info));
			reqQueue.delete(port);
		}
	});
};

/** 缓存请求 */
export const storageReq = async (parameter: storageReqParams) => {
	let { port, params, callback } = parameter;
	let key = `port:${port},params:${JSON.stringify(params)}`;
	key = md5(key);

	let info = uni.getStorageSync(key);
	info && callback(info);

	info = await request.get({ port, params, showLoad: !info });
	uni.setStorageSync(key, info);
	callback(info);
};

export const setParams = async (params: any, service?: service) => {
	let plat = getPlatform(),
		{ token, plat: _plat, merchantToken } = systemInfo();

	plat = plat == 'h5' ? _plat : plat;

	params.sign && delete params.sign;
	params.header && delete params.header;
	token && (params.token = token);
	merchantToken && (params.token2 = merchantToken);

	params = {
		plat,
		token: '',
		...params,
	};

	// #ifdef APP-PLUS
	let version = uni.getSystemInfoSync().appWgtVersion;
	version = version?.replace('-test', '');
	params.version = version;
	// #endif

	for (let key in params) {
		needAES.includes(key) && params[key] && (params[key] = AES.encrypt(params[key]));
	}

	return params;
};

export const createSign = (params: any, service?: service) => {
	params = cloneDeep(params); //数据隔离
	service && (params.service = service);

	const arr = Object.keys(params).sort();
	let str = '';
	for (let key of arr) str += params[key];
	str += systemInfo().api.SIGN_KEY;

	return md5(md5(md5(str)));
};

const toRequest = async (reqData: reqData): Promise<any> => {
	let { method, port: service, params = {}, resErr = false, showLoad = true } = reqData,
		cloneParams = cloneDeep(params),
		header: any = cloneParams?.header || {
			'content-type': 'application/x-www-form-urlencoded',
		};

	showLoad && uni.showLoading({});
	for (let key in cloneParams) cloneParams[key] === undefined && delete cloneParams[key];
	cloneParams = await setParams(cloneParams, service);
	cloneParams.sign = createSign(cloneParams, service);

	// 请求配置
	const config: any = {
		method,
		data: {} as any,
		url: `${systemInfo().api.API_ROOT}?service=${service}`,
	};

	if (method == 'GET') {
		config.data = cloneParams;
	} else {
		config.header = header;
		config.data = { service, ...cloneParams };
	}
	try {
		let { data = {} }: any = await uni.request(config);
		showLoad && uni.hideLoading();
		// console.log({ url: config.url, service, params: cloneParams, res: data.data });

		if (data?.ret == 200) {
			let { code, info, msg } = data.data,
				resErrInfo = { code, ...info, msg };

			switch (Number(code)) {
				case 0:
					return resErr ? resErrInfo : info;

				case 1030043:
					systemInfo().token = '';
					if (service != port.MEMBER_GETMEMBERDETAIL) {
						toLogin();
					}
					break;

				case 1050043:
					systemInfo().merchantToken = '';
					toMerchantLogin();
					break;

				default:
					if (!resErr) uni.showToast({ title: msg, icon: 'none' });
					else return resErrInfo;
			}

			return Promise.reject(resErrInfo);
		} else {
			return { ...data, info: {}, code: data.ret };
		}
	} catch (error) {
		console.error(error);
	}
};

const request = {
	async get(port: service | params, params?: any): Promise<resData> {
		return await this.request({ method: 'GET', port, params });
	},
	async post(port: service | params, params?: any): Promise<resData> {
		return await this.request({ method: 'POST', port, params });
	},
	async request(parameter: { method: method; port: service | params; params: any }) {
		let { method, port, params } = parameter,
			reqData: reqData = typeof port == 'string' ? { method, port, params } : { method, ...port };

		try {
			return await toRequest(reqData);
		} catch (error) {
			return Promise.reject(error);
		}
	},
};

export default request;
